import { Card } from "~/components/ui/card";
import { Button } from "~/components/ui/button";
import { Badge } from "~/components/ui/badge";
import {
  ArrowRightIcon,
  LightningBoltIcon,
  CheckCircledIcon,
} from "@radix-ui/react-icons";
import { IntegrationModel } from "~/lib/data/integrations";
import useIntegrationSetup from "~/hooks/useIntegrationSetup";
import { cn } from "~/lib/utils";
import * as Dialog from "@radix-ui/react-dialog";
import { useNavigate } from "@remix-run/react";
import { useState } from "react";
import { useSession } from "~/contexts/auth";

type IntegrationSetupCardProps = {
  integration: IntegrationModel;
};

export function IntegrationSetupCard({
  integration,
}: IntegrationSetupCardProps) {
  const { integrationCode } = useIntegrationSetup();
  const isSetupInProgress = integrationCode === integration.id;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { isAuthenticated, profile } = useSession();
  const navigate = useNavigate();

  const handleSetupIntegration = (id: string) => {
    if (isAuthenticated && !!profile) {
      navigate(`/integrations/setup/${id}`);
    } else {
      setIsDialogOpen(true);
    }
  };

  return (
    <>
      <Card className="overflow-hidden transition-all duration-300 hover:shadow-2xl bg-gradient-to-br from-zinc to-ceramic-50 dark:from-gray-800 dark:to-gray-900 border-none rounded-2xl flex flex-col">
        <div className="p-8">
          <div className="flex items-center justify-center mb-8 relative">
            <div className="h-20 w-20 bg-gradient-to-br from-blue-100 to-blue-200 dark:from-blue-900 dark:to-blue-800 rounded-2xl flex items-center justify-center shadow-lg">
              <img
                src={integration.source?.image}
                alt={integration.source?.name}
                className="max-w-[60%] max-h-[60%] object-contain"
              />
            </div>
            <ArrowRightIcon className="h-8 w-8 text-emerald-600 dark:text-emerald-400 mx-4 z-10" />
            <div className="h-20 w-20 bg-gradient-to-br from-purple-100 to-purple-200 dark:from-purple-900 dark:to-purple-800 rounded-2xl flex items-center justify-center shadow-lg">
              <img
                src={integration.target?.image}
                alt={integration.target?.name}
                className="max-w-[60%] max-h-[60%] object-contain"
              />
            </div>
            <div className="absolute inset-0 bg-gradient-to-r from-transparent via-emerald-200/20 dark:via-emerald-700/20 to-transparent blur-sm"></div>
          </div>
          <h3 className="text-xl font-bold text-gray-800 dark:text-white mb-3 text-center">
            {integration.source?.name} → {integration.target?.name}
          </h3>
          <p className="text-sm text-gray-600 dark:text-gray-300 mb-6 text-center line-clamp-3">
            {integration?.description}
          </p>
          <div className="flex items-center justify-center space-x-2 mb-6">
            <Badge
              variant="secondary"
              className="text-xs bg-amber-100 dark:bg-amber-800/50 text-amber-700 dark:text-amber-300"
            >
              {integration?.category}
            </Badge>
            <Badge
              variant="secondary"
              className="text-xs bg-blue-100 dark:bg-blue-800/50 text-blue-700 dark:text-blue-300"
            >
              <LightningBoltIcon className="w-3 h-3 mr-1" />
              Quick Setup
            </Badge>
          </div>
        </div>
        <div className="p-6 bg-gray-50 dark:bg-gray-800/50 flex items-center justify-between sticky bottom-0">
          <div className="flex items-center">
            <CheckCircledIcon className="w-5 h-5 text-emerald-500 mr-2" />
            <span className="text-sm text-gray-600 dark:text-gray-400">
              Easy to configure
            </span>
          </div>
          <Button
            className={cn(
              "transition-all duration-300 transform hover:scale-105",
              isSetupInProgress
                ? "bg-amber-500 hover:bg-amber-600 text-white"
                : "bg-emerald-500 hover:bg-emerald-600 text-white"
            )}
            onClick={() => handleSetupIntegration(integration.id)}
          >
            {isSetupInProgress ? "Continue Setup" : "Setup Integration"}
          </Button>
        </div>
      </Card>

      <Dialog.Root open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black/50 backdrop-blur-sm" />
          <Dialog.Content className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white dark:bg-gray-800 p-8 rounded-2xl shadow-2xl max-w-md w-full border border-gray-200 dark:border-gray-700">
            <Dialog.Title className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">
              Registration Required
            </Dialog.Title>
            <Dialog.Description className="text-gray-600 dark:text-gray-300 mb-6">
              To set up this integration, you need to register an organisation.
              Would you like to create your account now?
            </Dialog.Description>
            <div className="flex items-center justify-between mb-6">
              <div className="flex items-center">
                <LightningBoltIcon className="w-5 h-5 text-amber-500 mr-2" />
                <span className="text-sm text-gray-600 dark:text-gray-400">
                  Quick registration process
                </span>
              </div>
              <Badge
                variant="secondary"
                className="text-xs bg-emerald-100 dark:bg-emerald-800 text-emerald-700 dark:text-emerald-300"
              >
                Free to start
              </Badge>
            </div>
            <div className="flex justify-end space-x-4">
              <Dialog.Close asChild>
                <Button
                  variant="outline"
                  className="border-gray-300 dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  Cancel
                </Button>
              </Dialog.Close>
              <Button
                onClick={() => {
                  setIsDialogOpen(false);
                  navigate("/signup");
                }}
                className="bg-emerald-500 hover:bg-emerald-600 text-white transition-all duration-300 transform hover:scale-105"
              >
                Register Now
              </Button>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
}
